.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 50px;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    width: 300px;
    max-width: 50%;
    max-height: 150vh;
    overflow-y: auto;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(204, 33, 33, 0.1);
    position: relative;
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
  }
  
  .title-input,
  .content-textarea,
  .submit-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .content-textarea {
    height: 100px;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }
  