footer {
    background-color: #212529; 
    color: #fff; 
    padding: 5px ; 
    text-align: center;
  }
  
 
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
  }
  
  .container {
    flex: 1;
  }
  
  