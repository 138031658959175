.squares-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .squares-background::before,
  .squares-background::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  /* Blue Square */
  .squares-background::before {
    content: "";
    background-color: #007bff; /* Blue */
    opacity: 0.7; /* Adjust opacity as desired */
    transform: rotate(45deg);
  }
  
  /* Red Square */
  .squares-background::after {
    content: "";
    background-color: #ff0000; /* Red */
    opacity: 0.7; /* Adjust opacity as desired */
    transform: rotate(45deg);
  }
  
  /* Grey Square */
  .squares-background::before,
  .squares-background::after {
    width: 1000px; /* Adjust width as desired */
    height: 1000px; /* Adjust height as desired */
  }
  
  /* Position squares randomly */
  .squares-background::before {
    top: -10%;
    left: 10%;
  }
  
  .squares-background::after {
    bottom: -10%;
    right: 10%;
  }