.commentClass {
    border: 1px solid #e1e1e1;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    position: relative;
    transition: margin-left 0.3s ease-in-out;
}

.commentClass:hover {
    box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
}

.commentClass p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
}

button {
    margin-top: 8px;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

button:hover {
    background-color: #0056b3;
}

button:focus {
    outline: none;
}

.commentClass .commentClass {
    margin-left: 20px;
}
