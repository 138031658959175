.form {
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 50px;
}

.form-control {
  text-align: center;
}

.message {
  font-weight: bold;
  font-style: italic;
}

input[type="checkbox"] {
  margin-right: -20px; /* Reduces the space between checkbox and label */
}

.form-check-input.form-check-input {
  border: 1px solid black;
}
