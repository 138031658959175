.trail-details {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .trail-details h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .trail-details p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .loading {
    text-align: center;
    font-size: 20px;
    color: #333;
  }
  